<template>
  <q-layout view="hHh Lpr lFf">
    <alert-component ref="alert"></alert-component>
    <confirm-component ref="confirm"></confirm-component>
    <div
      v-if="isLoading"
      :class="$style.spinner"
    >
      <q-spinner
        :thickness="3"
        color="primary"
        size="80px"
      ></q-spinner>
    </div>
    <app-header v-model="drawer"></app-header>
    <app-menu v-model="drawer"></app-menu>
    <q-page-container>
      <q-page class="flex column">
        <router-view></router-view>
      </q-page>
    </q-page-container>
  </q-layout>
</template>
<script>
  import { defineComponent, ref, computed, provide, onMounted } from 'vue';
  import { LoaderStore } from 'mph-helper';
  import AlertComponent from '@/common/components/dialogs/alert.component.vue';
  import ConfirmComponent from '@/common/components/dialogs/confirm.component.vue';
  import AppHeader from '@/common/components/app-header/app-header.component.vue';
  import AppMenu from '@/common/components/app-menu/app-menu.component.vue';

  export default defineComponent({
    setup() {
      const isLoading = computed(() => LoaderStore.getters.isLoading);
      const drawer = ref(true);
      const alert = ref(null);
      const confirm = ref(null);
      onMounted(() => {
        provide('$confirm', confirm.value.open);
        provide('$alert', alert.value.open);
      });
      return {
        isLoading,
        drawer,
        alert,
        confirm,
      };
    },
    components: {
      AlertComponent,
      ConfirmComponent,
      AppHeader,
      AppMenu,
    },
  });
</script>
<style lang="scss" module>
  .spinner {
    position: absolute;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
