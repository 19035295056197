import Notify from 'quasar/src/plugins/Notify.js';;
import '@quasar/extras/roboto-font/roboto-font.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@/styles/index.scss';

export default {
  plugins: {
    Notify,
  },
  config: {
    notify: {
      position: 'top',
      timeout: 1000,
    },
    brand: {
      primary: '#1976d2',
      positive: '#21ba45',
      warning: '#f2c037',
      negative: '#c10015',
    },
  },
};
