import { NavigationGuardNext, RouteComponent, RouteLocation } from 'vue-router';
import { hasFeature } from '@/common/helpers/access.helper';
import { Feature } from '@/common/constants/security';

const Sources = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/sources/sources.component.vue');
const SourcesSearch = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/sources/search/search.component.vue');
const Source = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/sources/source/source.component.vue');
const Categories = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/categories/categories.component.vue');
const CategoriesSearch = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/categories/search/search.component.vue');
const Category = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/categories/category/category.component.vue');
const Countries = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/countries/countries.component.vue');
const CountriesSearch = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/countries/search/search.component.vue');
const Country = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/countries/country/country.component.vue');
const CountryFiles = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/country-files/country-files.component.vue');
const CountryFilesSearch = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/country-files/search/search.component.vue');
const CountryFile = (): Promise<RouteComponent> => import(/* webpackChunkName: "risk-level" */ '@/components/risk-level/country-files/country-file/country-file.component.vue');

export default [{
  component: Sources,
  name: 'risk-level.sources',
  path: '/risk-level/sources',
  redirect: { name: 'risk-level.sources.search' },
  children: [{
    component: SourcesSearch,
    name: 'risk-level.sources.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelSourcesList));
    },
  }, {
    component: Source,
    name: 'risk-level.sources.source',
    path: 'source/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelSourcesWrite));
    },
  }],
}, {
  component: Categories,
  name: 'risk-level.categories',
  path: '/risk-level/categories',
  redirect: { name: 'risk-level.categories.search' },
  children: [{
    component: CategoriesSearch,
    name: 'risk-level.categories.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCategoriesList));
    },
  }, {
    component: Category,
    name: 'risk-level.categories.category',
    path: 'category/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCategoriesWrite));
    },
  }],
}, {
  component: Countries,
  name: 'risk-level.countries',
  path: '/risk-level/countries',
  redirect: { name: 'risk-level.countries.search' },
  children: [{
    component: CountriesSearch,
    name: 'risk-level.countries.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCountriesList));
    },
  }, {
    component: Country,
    name: 'risk-level.countries.country',
    path: 'country/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCountriesWrite));
    },
  }],
}, {
  component: CountryFiles,
  name: 'risk-level.country-files',
  path: '/risk-level/country-files',
  redirect: { name: 'risk-level.country-files.search' },
  children: [{
    component: CountryFilesSearch,
    name: 'risk-level.country-files.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCountryFilesList));
    },
  }, {
    component: CountryFile,
    name: 'risk-level.country-files.country-file',
    path: 'country-file/:code?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.riskLevelCountryFilesWrite));
    },
  }],
}];
