import { http } from '@/http';
import { CountryDictionary } from '@/model/common/country-dictionary';
import { RegionDictionary } from '@/model/common/region-dictionary';

export class DictionariesService {
  static getCountries(): Promise<Array<CountryDictionary>> {
    return http.get('/mbo/countries/dictionary')
      .then(({ data }) => data);
  }

  static getRegions(): Promise<Array<RegionDictionary>> {
    return http.get('/mbo/regions/dictionary')
      .then(({ data }) => data);
  }
}
