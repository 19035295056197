import { Feature } from '@/common/constants/security';

const MenuList = [{
  routing: { name: 'dashboard' },
  icon: 'dashboard',
  label: 'MBO.DASHBOARD',
  feature: Feature.eventsRead,
}, {
  routing: { name: 'events' },
  icon: 'whatshot',
  label: 'MBO.EVENTS',
  feature: Feature.eventsList,
}, {
  routing: { name: 'regions' },
  icon: 'explore',
  label: 'MBO.REGIONS',
  feature: Feature.regionsList,
}, {
  routing: { name: 'users' },
  icon: 'people',
  label: 'MBO.USERS',
  feature: Feature.usersList,
}, {
  icon: 'speed',
  label: 'MBO.RISK_LEVEL',
  feature: [Feature.riskLevelSourcesList, Feature.riskLevelCategoriesList, Feature.riskLevelCategoriesList],
  children: [{
    routing: { name: 'risk-level.sources' },
    icon: 'account_tree',
    label: 'MBO.SOURCES',
    feature: Feature.riskLevelSourcesList,
  }, {
    routing: { name: 'risk-level.categories' },
    icon: 'category',
    label: 'MBO.CATEGORIES',
    feature: Feature.riskLevelCategoriesList,
  }, {
    routing: { name: 'risk-level.countries' },
    icon: 'speed',
    label: 'MBO.RISK_LEVEL_COUNTRY',
    feature: Feature.riskLevelCategoriesList,
  }, {
    routing: { name: 'risk-level.country-files' },
    icon: 'folder',
    label: 'MBO.RISK_LEVEL_COUNTRY_FILES',
    // feature: Feature.riskLevelCategoriesList,
  }],
}];

export default MenuList;
