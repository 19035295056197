import { Component, App } from 'vue';
import CategoryLabel from '@/common/components/category-label/category-label.component.vue';
import CountryLabel from '@/common/components/country-label/country-label.component.vue';
import CountrySelect from '@/common/components/country-select/country-select.component.vue';
import DialogLayout from '@/common/components/layouts/dialog.layout.vue';
import InputDate from '@/common/components/input-date/input-date.component.vue';
import InputEditor from '@/common/components/input-editor/input-editor.component.vue';
import InputLanguages from '@/common/components/input-languages/input-languages.component.vue';
import RegionLabel from '@/common/components/region-label/region-label.component.vue';
import RegionSelect from '@/common/components/region-select/region-select.component.vue';
import Table from '@/common/components/table/table.component.vue';
import Tinymce from '@/common/components/tinymce/tinymce.component.vue';
import WorldLabel from '@/common/components/world-label/world-label.component.vue';

const components = {
  'em-category-label': CategoryLabel,
  'em-country-label': CountryLabel,
  'em-country-select': CountrySelect,
  'em-dialog-layout': DialogLayout,
  'em-input-date': InputDate,
  'em-input-editor': InputEditor,
  'em-input-languages': InputLanguages,
  'em-region-label': RegionLabel,
  'em-region-select': RegionSelect,
  'em-table': Table,
  'em-tinymce': Tinymce,
  'em-world-label': WorldLabel,
};

const register = (app: App<Element>): void => {
  Object
    .entries(components)
    .forEach(([key, value]) => {
      app.component(<string>key, <Component>value);
    });
};

export default {
  register,
};
