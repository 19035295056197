import { Module, ActionTree, MutationTree } from 'vuex';
import { DictionariesService } from '@/common/services/dictionaries.service';
import { RootState, DictionariesState } from '@/store/interface';

const moduleState: DictionariesState = {
  countries: [],
  regions: [],
};
const moduleActions: ActionTree<DictionariesState, RootState> = {
  getCountries({ state, commit }) {
    if (state.countries.length > 0) {
      return state.countries;
    }
    return DictionariesService.getCountries()
      .then((response) => {
        commit('setCountries', response);
        return state.countries;
      });
  },
  getRegions({ state, commit }) {
    if (state.regions.length > 0) {
      return state.regions;
    }
    return DictionariesService.getRegions()
      .then((response) => {
        commit('setRegions', response);
        return state.regions;
      });
  },
};
const moduleMutations: MutationTree<DictionariesState> = {
  setCountries(state, payload) {
    state.countries = payload || [];
  },
  setRegions(state, payload) {
    state.regions = payload || [];
  },
};
const module: Module<DictionariesState, RootState> = {
  namespaced: true,
  state: moduleState,
  actions: moduleActions,
  mutations: moduleMutations,
};

export const DictionariesStore = {
  name: 'DictionariesStore',
  module,
};
