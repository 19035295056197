<template>
  <q-table
    v-model:pagination="paginationObj"
    :rows="rows"
    :columns="columns"
    :row-key="rowKey"
    :title="title"
    :flat="$q.screen.gt.md"
    :class="$style.component"
    hide-pagination
    wrap-cells
    virtual-scroll
    binary-state-sort
    @request="onRequest"
  >
    <template
      #top
      v-if="$slots.top"
    >
      <slot name="top"></slot>
    </template>
    <template #body-cell="{ value }">
      <q-td :class="$style.component__cell">
        <div v-html="value"></div>
      </q-td>
    </template>
    <template #body-cell-avatar="{ row }">
      <q-td :class="$style.component__action">
        <slot
          name="avatar"
          :item="row"
        ></slot>
      </q-td>
    </template>
    <template #body-cell-scope="{ row }">
      <q-td :class="$style.component__cell">
        <slot
          :item="row"
          name="scope"
        ></slot>
      </q-td>
    </template>
    <template #body-cell-actions="{ row, rowIndex }">
      <q-td :class="$style.component__action">
        <div class="flex no-wrap justify-end">
          <slot
            name="actions"
            :item="row"
            :index="rowIndex"
          ></slot>
        </div>
      </q-td>
    </template>
    <template
      #bottom
      v-if="pagination"
    >
      <q-select
        :model-value="pagination.perPage"
        :options="PerPageOptions"
        outlined
        dense
        @update:model-value="onRowsPerPageChange"
      ></q-select>
      <q-space></q-space>
      <q-pagination
        :model-value="pagination.page"
        :max="pagination.pages"
        color="dark"
        input
        @update:model-value="onPageChange"
      ></q-pagination>
    </template>
  </q-table>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { PerPageOptions, Order } from '@/common/components/table/table.constant';

  export default defineComponent({
    props: {
      rows: {
        type: Array,
        required: true,
      },
      columns: {
        type: Array,
        required: true,
      },
      pagination: {
        type: Object,
      },
      rowKey: {
        type: String,
        default: 'id',
      },
      fetch: {
        type: Function,
      },
      title: {
        type: String,
      },
    },
    setup(props) {
      const paginationObj = computed(() => {
        const [sortBy] = Object.keys(props.pagination?.sort || []);
        const order = props.pagination?.sort[sortBy] || null;
        return ({
          sortBy,
          descending: (order && order === Order.desc) || null,
          page: props.pagination?.page,
          rowsPerPage: props.pagination?.perPage || 0,
          rowsNumber: props.pagination?.pages,
        });
      });

      function onRequest(payload) {
        const { sortBy, descending } = payload.pagination;
        props.fetch({
          sort: { [sortBy]: descending ? Order.desc : Order.asc },
        });
      }
      function onPageChange(payload) {
        props.fetch({
          page: payload,
        });
      }
      function onRowsPerPageChange(payload) {
        props.fetch({
          page: 1,
          perPage: payload,
        });
      }
      return {
        PerPageOptions,
        paginationObj,
        onRequest,
        onPageChange,
        onRowsPerPageChange,
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    &__action {
      text-align: right;
      width: 0;
    }
    :global {
      .q-table__top {
        padding: 12px 0;
      }
      .q-td {
        max-width: 800px;
      }
    }
  }
</style>
