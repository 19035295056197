<template>
  <div :class="[$style.component, { [$style.error]: error }]">
    <div :class="$style.component__title">
      <slot></slot>
    </div>
    <div :class="$style.component__input">
      <input
        :id="tinymceId"
        :value="modelValue"
      >
    </div>
  </div>
</template>
<script>
  import tinymce from 'tinymce/tinymce';
  import 'tinymce/themes/modern/theme';
  import 'tinymce/plugins/paste';
  import 'tinymce/plugins/link';
  import 'tinymce/plugins/textcolor';
  import 'tinymce/plugins/colorpicker';
  import 'tinymce/plugins/textpattern';
  import 'tinymce/plugins/imagetools';
  import 'tinymce/plugins/image';
  import 'tinymce/plugins/preview';
  import 'tinymce/plugins/code';
  import 'tinymce/plugins/lists';
  import 'tinymce/plugins/charmap';
  import { defineComponent, onBeforeMount, onMounted, onUnmounted, ref, watch, nextTick } from 'vue';
  import { useI18n } from 'vue-i18n';
  import fr from '@/common/components/tinymce/langs/fr.json';
  import { Language } from '@/common/constants/languages';

  tinymce.addI18n(Language.fr, fr);

  let idCounter = 0;

  export default defineComponent({
    props: {
      modelValue: {
        type: String,
        default: '',
      },
      options: {
        type: Object,
        default: () => ({}),
      },
      error: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const i18n = useI18n();
      const tinymceId = ref(null);

      function init() {
        const options = {
          skin_url: '/skins',
          selector: `#${tinymceId.value}`,
          plugins: ['paste', 'link'],
          width: '100%',
          height: 200,
          menubar: false,
          statusbar: false,
          branding: false,
        };
        if (props.options) {
          Object.assign(options, props.options);
        }
        options.setup = (editor) => {
          if (props.options && typeof props.options.setup === 'function') {
            props.options.setup(editor);
          }
          editor.on('keyup change', () => {
            emit('update:model-value', editor.getContent());
          });
        };
        tinymce.util.I18n.setCode(i18n.locale.value);
        tinymce.init(options);
      }

      onBeforeMount(() => {
        tinymceId.value = `tinymce-${idCounter}`;
        idCounter += 1;
      });
      onMounted(() => {
        nextTick(init);
      });
      onUnmounted(() => {
        const instance = tinymce.get(tinymceId);
        if (instance) {
          instance.destroy();
        }
      });
      watch(i18n.locale, (payload) => {
        tinymce.util.I18n.setCode(payload);
      });
      return {
        tinymceId,
      };
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/quasar.variables';

  .component {
    &__title {
      padding: 4px 0;
    }
    &__input {
      border: 2px solid transparent;
      border-radius: 4px;
      display: flex;
    }
    :global {
      .mce-tinymce {
        width: auto;
        border-color: rgba(0, 0, 0, 0.12);
        border-radius: inherit;
        box-shadow: none;
        overflow: hidden;
      }
      .mce-panel {
        background-color: white;
      }
      .mce-btn {
        background-color: white;
        float: left;
      }
    }
  }
  .error {
    .component__title {
      color: $negative;
    }
    .component__input {
      border-color: $negative;
    }
  }
</style>
