import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Dashboard from '@/components/dashboard';
import Events from '@/components/events';
import Regions from '@/components/regions';
import RiskLevel from '@/components/risk-level';
import Users from '@/components/users';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: { name: 'events' },
    components: {},
    children: [],
  },
  Dashboard,
  Events,
  Regions,
  ...RiskLevel,
  Users,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
