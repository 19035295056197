export enum Role {
  superAdmin = 'SUPERADMIN',
}

export enum Feature {
  countryList = 'LIST_MBO_COUNTRY',
  eventsAdmin = 'ADMIN_MBO_EVENT',
  eventsRead = 'READ_MBO_EVENT',
  eventsList = 'LIST_MBO_EVENT',
  eventsWrite = 'WRITE_MBO_EVENT',
  templateRender = 'RENDER_MBO_EVENT_TEMPLATE',
  eventsDelete = 'DELETE_MBO_EVENT',
  regionsAdmin = 'ADMIN_MBO_REGION',
  regionsRead = 'READ_MBO_REGION',
  regionsList = 'LIST_MBO_REGION',
  regionsWrite = 'WRITE_MBO_REGION',
  regionsDelete = 'DELETE_MBO_REGION',
  usersAdmin = 'ADMIN_MAILING_SUBSCRIPTIONS',
  usersRead = 'READ_MAILING_SUBSCRIPTIONS',
  usersList = 'LIST_MAILING_SUBSCRIPTIONS',
  usersWrite = 'WRITE_MAILING_SUBSCRIPTIONS',
  usersDelete = 'DELETE_MAILING_SUBSCRIPTIONS',
  riskLevelSourcesList = 'LIST_MBO_RISK_LEVEL_SOURCE',
  riskLevelSourcesWrite = 'WRITE_MBO_RISK_LEVEL_SOURCE',
  riskLevelSourcesDelete = 'WRITE_MBO_RISK_LEVEL_SOURCE',
  riskLevelCategoriesList = 'LIST_MBO_RISK_LEVELS_CATEGORY',
  riskLevelCategoriesWrite = 'WRITE_MBO_RISK_LEVELS_CATEGORY',
  riskLevelCategoriesDelete = 'DELETE_MBO_RISK_LEVELS_CATEGORY',
  riskLevelCountriesList = 'LIST_MBO_COUNTRY_RISK_LEVEL',
  riskLevelCountriesWrite = 'WRITE_MBO_COUNTRY_RISK_LEVEL',
  riskLevelCountriesDelete = 'DELETE_MBO_COUNTRY_RISK_LEVEL',
  riskLevelCountryFilesAdmin = 'ADMIN_MBO_COUNTRY_FILES',
  riskLevelCountryFilesRead = 'READ_MBO_COUNTRY_FILES',
  riskLevelCountryFilesWrite = 'WRITE_MBO_COUNTRY_FILES',
  riskLevelCountryFilesDelete = 'DELETE_MBO_COUNTRY_FILES',
  riskLevelCountryFilesPublish = 'PUBLISH_MBO_COUNTRY_FILES',
  riskLevelCountryFilesList = 'LIST_MBO_COUNTRY_FILES',
}
