import { NavigationGuardNext, RouteComponent, RouteLocation } from 'vue-router';
import { hasFeature } from '@/common/helpers/access.helper';
import { Feature } from '@/common/constants/security';

const Regions = (): Promise<RouteComponent> => import(/* webpackChunkName: "regions" */ '@/components/regions/regions.component.vue');
const Search = (): Promise<RouteComponent> => import(/* webpackChunkName: "regions" */ '@/components/regions/search/search.component.vue');
const Region = (): Promise<RouteComponent> => import(/* webpackChunkName: "regions" */ '@/components/regions/region/region.component.vue');

export default {
  component: Regions,
  name: 'regions',
  path: '/regions',
  redirect: { name: 'regions.search' },
  children: [{
    component: Search,
    name: 'regions.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.regionsList));
    },
  }, {
    component: Region,
    name: 'regions.region',
    path: 'region/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.regionsWrite));
    },
  }],
};
