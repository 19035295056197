<template>
  <q-select
    v-model="model"
    :class="$style.component"
    :error="error"
    :error-message="errorMessage"
    :options="options"
    :label="label"
    :hide-bottom-space="hideBottomSpace"
    :multiple="multiple"
    :use-chips="multiple"
    :clearable="clearable"
    :option-label="optionLabel"
    option-value="code"
    input-debounce="0"
    use-input
    outlined
    dense
    ref="select"
    @filter="countryFilter"
  ></q-select>
</template>
<script>
  import { computed, defineComponent, onMounted, ref, nextTick } from 'vue';
  import { useStore } from 'vuex';
  import i18n from '@/i18n';
  import { DictionariesStore } from '@/store/modules/dictionaries.store';

  export default defineComponent({
    props: {
      modelValue: {
        type: [Array, Object, String],
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
      },
      label: {
        type: String,
      },
      hideBottomSpace: {
        type: Boolean,
        default: false,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      returnObject: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const { state, dispatch } = useStore();
      const select = ref(null);
      const countries = computed(() => state[`${DictionariesStore.name}`].countries);
      const options = ref(countries.value);
      const model = computed({
        get() {
          if (props.returnObject) {
            const { code } = props.modelValue;
            return code ? props.modelValue : null;
          }
          if (props.multiple) {
            const uniqueModelValue = [...new Set(props.modelValue)];
            return countries.value.filter(({ code }) => uniqueModelValue.includes(code));
          }
          return countries.value.find(({ code }) => code === props.modelValue);
        },
        async set(payload) {
          let value = payload;
          if (!props.returnObject) {
            value = props.multiple ? payload.map(({ code }) => code) : payload?.code;
          }
          emit('update:model-value', value);
          await nextTick();
          select.value.hidePopup();
        },
      });
      onMounted(() => {
        dispatch(`${DictionariesStore.name}/getCountries`);
      });
      return {
        select,
        options,
        model,
        optionLabel(payload) {
          return payload.name[i18n.global.locale];
        },
        countryFilter(value, update) {
          if (value === '') {
            update(() => {
              options.value = countries.value;
            });
          } else {
            update(() => {
              const needle = value.toLowerCase();
              options.value = countries.value.filter((item) => {
                const name = (item.name[i18n.global.locale] || '').toLowerCase();
                return name.indexOf(needle) > -1;
              });
            });
          }
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    :global .q-chip {
      margin: 2px 4px 0;
    }
  }
</style>
