<template>
  <q-card
    :class="$style.component"
    bordered
    flat
  >
    <q-tabs
      v-model="tab"
      dense
      active-bg-color="light-blue-1"
      indicator-color="primary"
      align="justify"
      narrow-indicator
    >
      <q-tab
        v-for="({ code }, index) in languages"
        :key="index"
        :name="code"
        :label="code"
        :class="isError(code) ? ['text-negative', 'bg-red-1'] : 'text-primary'"
      ></q-tab>
    </q-tabs>
    <q-separator></q-separator>
    <q-tab-panels
      v-model="tab"
      animated
    >
      <q-tab-panel
        v-for="({ code }, index) in languages"
        :key="index"
        :name="code"
      >
        <slot
          :code="code"
          name="input"
        ></slot>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { LanguagesStore } from 'mph-helper';
  import i18n from '@/i18n';

  export default defineComponent({
    props: {
      errors: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      const store = useStore();
      const languages = computed(() => store.state[LanguagesStore.name].languages);
      return {
        tab: ref(i18n.global.locale),
        languages,
        isError(payload) {
          return props.errors.some((item) => item[payload] && item[payload].$error);
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    :global .q-tab-panel {
      padding: 8px;
    }
  }
</style>
