import { NavigationGuardNext, RouteComponent, RouteLocation } from 'vue-router';
import { hasFeature } from '@/common/helpers/access.helper';
import { Feature } from '@/common/constants/security';

const Users = (): Promise<RouteComponent> => import(/* webpackChunkName: "users" */ '@/components/users/users.component.vue');
const Search = (): Promise<RouteComponent> => import(/* webpackChunkName: "users" */ '@/components/users/search/search.component.vue');
const User = (): Promise<RouteComponent> => import(/* webpackChunkName: "users" */ '@/components/users/user/user.component.vue');

export default {
  component: Users,
  name: 'users',
  path: '/users',
  redirect: { name: 'users.search' },
  children: [{
    component: Search,
    name: 'users.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.usersList));
    },
  }, {
    component: User,
    name: 'users.user',
    path: 'user/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.usersWrite));
    },
  }],
};
