import { createApp, ref } from 'vue';
import Quasar from 'quasar/src/vue-plugin.js';;
import VueClickAway from 'vue3-click-away';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import i18n from '@/i18n';
import QuasarOptions from '@/quasar';
import CommonComponents from '@/common/components';

const AppInstance = createApp(App);

CommonComponents.register(AppInstance);

(async () => {
  await store.dispatch('initApp');
  AppInstance
    .provide('$autoRefresh', ref(true))
    .use(VueClickAway)
    .use(Quasar, QuasarOptions)
    .use(store)
    .use(router)
    .use(i18n)
    .mount('#app');
})();
