export const RiskLevel = {
  none: 0,
  risk1: 1,
  risk2: 2,
  risk3: 3,
  risk4: 4,
  risk5: 5,
};

export const RiskLevels = [
  RiskLevel.risk1,
  RiskLevel.risk2,
  RiskLevel.risk3,
  RiskLevel.risk4,
  RiskLevel.risk5,
];

export const RiskLevelColor = {
  [RiskLevel.none]: 'primary',
  [RiskLevel.risk1]: 'green',
  [RiskLevel.risk2]: 'yellow',
  [RiskLevel.risk3]: 'orange',
  [RiskLevel.risk4]: 'deep-orange',
  [RiskLevel.risk5]: 'negative',
};
