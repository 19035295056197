<template>
  <div>
    <q-avatar
      color="primary"
      text-color="white"
      size="sm"
    >
      <q-icon
        size="xs"
        name="flag"
      >
        <q-tooltip>{{ $t('MBO.SCOPE_COUNTRY') }}</q-tooltip>
      </q-icon>
    </q-avatar>
    <div class="q-mx-xs inline-block">{{ countries }}</div>
  </div>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import i18n from '@/i18n';

  export default defineComponent({
    props: {
      countryNames: {
        type: Array,
        default: () => [],
      },
    },
    setup(props) {
      return {
        countries: computed(() => props.countryNames
            .map((item) => item[i18n.global.locale])
            .join(', ')),
      };
    },
  });
</script>
