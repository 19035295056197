import { NavigationGuardNext, RouteComponent, RouteLocation } from 'vue-router';
import { hasFeature } from '@/common/helpers/access.helper';
import { Feature } from '@/common/constants/security';

const Dashboard = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/dashboard.component.vue');
// const Search = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/search/search.component.vue');
const RecentActivities = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/recent-activities/recent-activities.component.vue');
// const UpcomingEvents = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/upcoming-events/upcoming-events.component.vue');
// const LoggedUsers = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/logged-users/logged-users.component.vue');
// const Statistics = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/statistics/statistics.component.vue');
// const PossibleIssues = (): Promise<RouteComponent> => import(/* webpackChunkName: "dashboard" */ '@/components/dashboard/possible-issues/possible-issues.component.vue');

export default {
  component: Dashboard,
  name: 'dashboard',
  path: '/dashboard',
  redirect: { name: 'dashboard.recent-activities' },
  children: [{
  //   component: Search,
  //   name: 'dashboard.search',
  //   path: 'search',
  // }, {
    component: RecentActivities,
    name: 'dashboard.recent-activities',
    path: 'recent-activities',
    label: 'Recent activities',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.eventsRead));
    },
  // }, {
  //   component: UpcomingEvents,
  //   name: 'dashboard.upcoming-events',
  //   path: 'upcoming-events',
  //   label: 'Upcoming events',
  // }, {
  //   component: LoggedUsers,
  //   name: 'dashboard.logged-users',
  //   path: 'logged-users',
  //   label: 'Logged users',
  // }, {
  //   component: Statistics,
  //   name: 'dashboard.statistics',
  //   path: 'statistics',
  //   label: 'Statistics',
  // }, {
  //   component: PossibleIssues,
  //   name: 'dashboard.possible-issues',
  //   path: 'possible-issues',
  //   label: 'Possible issues',
  }],
};
