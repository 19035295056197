import { computed } from 'vue';
import i18n from '@/i18n';

export const Category = {
  health: 'HEALTH',
  environment: 'ENVIRONMENT',
  security: 'SECURITY',
  geopolitics: 'GEOPOLITICS',
  cyber: 'CYBER',
};

export const Categories = computed(() => [
  Category.health,
  Category.environment,
  Category.security,
  Category.geopolitics,
  Category.cyber,
].map((item) => ({
  label: i18n.global.t(`MBO.${item}`),
  value: item,
})));

export const CategoryIcon = {
  [Category.health]: 'healing',
  [Category.environment]: 'public',
  [Category.security]: 'report_problem',
  [Category.geopolitics]: 'contact_support',
  [Category.cyber]: 'memory',
};
