<template>
  <div>
    <q-avatar
      color="green"
      text-color="white"
      size="sm"
    >
      <q-icon
        size="xs"
        name="mode_of_travel"
      >
        <q-tooltip>{{ $t('MBO.SCOPE_REGION') }}</q-tooltip>
      </q-icon>
    </q-avatar>
    <div class="q-mx-xs inline-block">{{ region }}</div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue';
  import i18n from '@/i18n';

  export default defineComponent({
    props: {
      regionName: {
        type: Object,
        default: () => ({}),
      },
    },
    setup(props) {
      return {
        region: props.regionName[i18n.global.locale],
      };
    },
  });
</script>
