import { NavigationGuardNext, RouteComponent, RouteLocation } from 'vue-router';
import { hasFeature } from '@/common/helpers/access.helper';
import { Feature } from '@/common/constants/security';

const Events = (): Promise<RouteComponent> => import(/* webpackChunkName: "events" */ '@/components/events/events.component.vue');
const Search = (): Promise<RouteComponent> => import(/* webpackChunkName: "events" */ '@/components/events/search/search.component.vue');
const Event = (): Promise<RouteComponent> => import(/* webpackChunkName: "events" */ '@/components/events/event/event.component.vue');

export default {
  component: Events,
  name: 'events',
  path: '/events',
  redirect: { name: 'events.search' },
  children: [{
    component: Search,
    name: 'events.search',
    path: 'search',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.eventsList));
    },
  }, {
    component: Event,
    name: 'events.event',
    path: 'event/:id?',
    beforeEnter(to: RouteLocation, from: RouteLocation, next: NavigationGuardNext): void {
      return next(hasFeature(Feature.eventsWrite));
    },
  }],
};
