import { computed } from 'vue';
import i18n from '@/i18n';

export const Locale = computed(() => ({
  days: [
    i18n.global.t('MBO.MONDAY'),
    i18n.global.t('MBO.TUESDAY'),
    i18n.global.t('MBO.WEDNESDAY'),
    i18n.global.t('MBO.THURSDAY'),
    i18n.global.t('MBO.FRIDAY'),
    i18n.global.t('MBO.SATURDAY'),
    i18n.global.t('MBO.SUNDAY'),
  ],
  daysShort: [
    i18n.global.t('MBO.MONDAY_SHORT'),
    i18n.global.t('MBO.TUESDAY_SHORT'),
    i18n.global.t('MBO.WEDNESDAY_SHORT'),
    i18n.global.t('MBO.THURSDAY_SHORT'),
    i18n.global.t('MBO.FRIDAY_SHORT'),
    i18n.global.t('MBO.SATURDAY_SHORT'),
    i18n.global.t('MBO.SUNDAY_SHORT'),
  ],
  months: [
    i18n.global.t('MBO.JANUARY'),
    i18n.global.t('MBO.FEBRUARY'),
    i18n.global.t('MBO.MARCH'),
    i18n.global.t('MBO.APRIL'),
    i18n.global.t('MBO.MAY'),
    i18n.global.t('MBO.JUNE'),
    i18n.global.t('MBO.JULY'),
    i18n.global.t('MBO.AUGUST'),
    i18n.global.t('MBO.SEPTEMBER'),
    i18n.global.t('MBO.OCTOBER'),
    i18n.global.t('MBO.NOVEMBER'),
    i18n.global.t('MBO.DECEMBER'),
  ],
  monthsShort: [
    i18n.global.t('MBO.JANUARY_SHORT'),
    i18n.global.t('MBO.FEBRUARY_SHORT'),
    i18n.global.t('MBO.MARCH_SHORT'),
    i18n.global.t('MBO.APRIL_SHORT'),
    i18n.global.t('MBO.MAY_SHORT'),
    i18n.global.t('MBO.JUNE_SHORT'),
    i18n.global.t('MBO.JULY_SHORT'),
    i18n.global.t('MBO.AUGUST_SHORT'),
    i18n.global.t('MBO.SEPTEMBER_SHORT'),
    i18n.global.t('MBO.OCTOBER_SHORT'),
    i18n.global.t('MBO.NOVEMBER_SHORT'),
    i18n.global.t('MBO.DECEMBER_SHORT'),
  ],
}));
