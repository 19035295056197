import { SecurityStateInterface } from 'mph-helper';
import { http } from '@/http';

export class SecurityService {
  static getSecurity(): Promise<SecurityStateInterface['security']> {
    return http.get('/security')
      .then(({ data }) => data);
  }

  static securityLogout(): Promise<void> {
    return http.get('/security/logout')
      .then(({ data }) => data);
  }
}
