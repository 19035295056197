<template>
  <q-drawer
    v-model="drawer"
    :class="$style.component"
    :width="200"
    :breakpoint="$q.screen.sizes.xl"
    show-if-above
    bordered
    mini
  >
    <q-scroll-area class="fit">
      <q-list>
        <template
          v-for="(item, index) in menuList"
          :key="index"
        >
          <q-item
            v-ripple
            :to="item.routing"
            :active="isActive(item)"
            clickable
          >
            <q-item-section avatar>
              <q-icon :name="item.icon"></q-icon>
            </q-item-section>
            <q-item-section>
              <span>{{ $t(item.label) }}</span>
            </q-item-section>
            <q-menu
              v-if="item.children"
              :model-value="submenu"
              :anchor="$q.screen.gt.xs ? 'top right' : 'bottom left'"
              self="top left"
            >
              <q-list>
                <q-item
                  v-ripple
                  v-close-popup
                  v-for="(child, childIndex) in menuListChildren(item)"
                  :key="childIndex"
                  :to="child.routing"
                  clickable
                >
                  <q-item-section avatar>
                    <q-icon :name="child.icon"></q-icon>
                  </q-item-section>
                  <q-item-section>
                    <span>{{ $t(child.label) }}</span>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-item>
        </template>
      </q-list>
      <q-space></q-space>
      <q-separator></q-separator>
      <q-list>
        <q-item :class="$style.logout">
          <q-btn
            flat
            @click="onLogoutClick"
          >
            <q-icon name="power_settings_new"></q-icon>
          </q-btn>
        </q-item>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>
<script>
  import { defineComponent, computed, ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import MenuList from '@/common/components/app-menu/app-menu.constant';
  import { hasFeature } from '@/common/helpers/access.helper';

  export default defineComponent({
    emits: ['update:model-value'],
    props: {
      modelValue: {
        type: Boolean,
        default: true,
      },
    },
    setup(props, context) {
      const { dispatch } = useStore();
      return {
        drawer: computed({
          get() {
            return props.modelValue;
          },
          set(payload) {
            context.emit('update:model-value', payload);
          },
        }),
        submenu: ref(false),
        menuList: computed(() => MenuList.filter(({ feature }) => hasFeature(feature))),
        menuListChildren({ children }) {
          return children.filter(({ feature }) => hasFeature(feature));
        },
        isActive({ children }) {
          if (children) {
            const { name } = useRoute();
            return children.some(({ routing }) => name?.includes(routing.name));
          }
          return null;
        },
        onLogoutClick() {
          dispatch('securityLogout');
        },
      };
    },
  });
</script>
<style lang="scss" module>
  .component {
    .logout {
      min-height: 56px;
      padding: 0;
    }
    :global .q-scrollarea__content {
      display: flex;
      flex-direction: column;
    }
  }
</style>
