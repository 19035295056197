<template>
  <q-input
    :model-value="inputValue"
    :label="label"
    :dense="dense"
    :clearable="clearable"
    :error="error"
    :error-message="errorMessage"
    :hide-bottom-space="hideBottomSpace"
    outlined
    @clear="onClearClick"
  ></q-input>
  <q-popup-proxy
    :breakpoint="breakpoint"
    @before-show="beforeShow"
  >
    <q-date
      v-model="date"
      :options="options"
      :locale="Locale"
      :range="range"
      mask="YYYY-MM-DD"
    >
      <div class="row items-center justify-end q-gutter-sm">
        <q-btn
          v-close-popup
          :label="$t('MBO.CANCEL')"
          flat
        ></q-btn>
        <q-btn
          v-close-popup
          :label="$t('MBO.OK')"
          color="primary"
          flat
          @click="onAgreeClick"
        ></q-btn>
      </div>
    </q-date>
  </q-popup-proxy>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { Locale } from '@/common/components/input-date/input-date.constant';

  export default defineComponent({
    props: {
      modelValue: {
        type: [String, Object],
      },
      min: {
        type: String,
      },
      max: {
        type: String,
      },
      label: {
        type: String,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      error: {
        type: Boolean,
        default: false,
      },
      errorMessage: {
        type: String,
      },
      hideBottomSpace: {
        type: Boolean,
        default: false,
      },
      range: {
        type: Boolean,
        default: false,
      },
    },
    setup(props, { emit }) {
      const date = ref(props.modelValue);
      const modelPopup = ref(false);
      return {
        Locale,
        date,
        modelPopup,
        breakpoint: computed(() => window.innerWidth),
        inputValue: computed(() => {
          if (props.range && props.modelValue?.from && props.modelValue?.to) {
            return `${props.modelValue.from} - ${props.modelValue.to}`;
          }
          return props.modelValue;
        }),
        options(payload) {
          if (props.min) {
            return payload >= props.min.split('-').join('/');
          }
          if (props.max) {
            return payload <= props.max.split('-').join('/');
          }
          return true;
        },
        beforeShow() {
          date.value = props.modelValue;
        },
        onAgreeClick() {
          emit('update:model-value', date.value);
        },
        onClearClick() {
          emit('update:model-value', null);
        },
      };
    },
  });
</script>
