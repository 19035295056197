import { createStore, Module } from 'vuex';
import {
  LanguagesStore,
  LanguagesStateInterface,
  RenditionsStore,
  SecurityStore,
  SecurityStateInterface,
} from 'mph-helper';
import i18n from '@/i18n';
import { RootState } from '@/store/interface';
import { DictionariesStore } from '@/store/modules/dictionaries.store';
import { LanguagesService } from '@/common/services/languages.service';
import { RenditionsService } from '@/common/services/renditions.service';
import { SecurityService } from '@/common/services/security.service';

export default createStore({
  actions: {
    getLanguages({ dispatch }) {
      return dispatch(`${LanguagesStore.name}/getLanguages`, {
        action: LanguagesService.getLanguages,
        i18n,
      });
    },
    getRenditions({ dispatch }) {
      return dispatch(`${RenditionsStore.name}/getRenditions`, {
        action: RenditionsService.getRenditions,
        i18n,
      });
    },
    getSecurity({ dispatch }) {
      return dispatch(`${SecurityStore.name}/getSecurity`, SecurityService.getSecurity);
    },
    securityLogout({ dispatch }) {
      return dispatch(`${SecurityStore.name}/securityLogout`, SecurityService.securityLogout);
    },
    async initApp({ dispatch }) {
      await dispatch('getLanguages');
      await dispatch('getRenditions');
      await dispatch('getSecurity');
    },
  },
  modules: {
    [DictionariesStore.name]: DictionariesStore.module,
    [LanguagesStore.name]: LanguagesStore.module as Module<LanguagesStateInterface, RootState>,
    [RenditionsStore.name]: RenditionsStore.module as Module<undefined, RootState>,
    [SecurityStore.name]: SecurityStore.module as Module<SecurityStateInterface, RootState>,
  },
});
