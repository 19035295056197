<template>
  <q-avatar
    color="orange"
    text-color="white"
    size="sm"
  >
    <q-icon
      size="xs"
      name="language"
    >
      <q-tooltip>{{ $t('MBO.SCOPE_WORLD') }}</q-tooltip>
    </q-icon>
  </q-avatar>
</template>
